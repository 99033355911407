import React from "react";

import LayoutGeneral from "../components/LayoutGeneral";
import Section from "../components/Section";
import SectionRow from "../components/SectionRow";
import SVGCurve from "../components/SVGCurve";
import SEO from "../components/SEO";
import TextDiv from "../components/TextDiv";
import TextParagraph from "../components/TextParagraph";
import AppointmentDiv from "../components/AppointmentDiv";

function FeedbackPage() {
    return (
        <LayoutGeneral>
            <SEO
                keywords={[`Agile Psychiatry`, `Brian Nahas MD`, `Feedback`, `Problems`]}
                title="Feedback"
                description="Provide feedback to Agile Psychiatry"
            />

            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <h2 className="text-3xl text-center mb-2">Feedback</h2>
                        <TextParagraph className="mb-8">
                            If your feedback is patient related or a medical matter, please use the
                            patient portal or contact Agile Psychiatry directly via phone.
                        </TextParagraph>
                        <TextParagraph className="mb-8">
                            Otherwise, please submit your feedback to Agile Psychiatry using
                            the form below.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-light"/>
            <Section shade="light">
                <SectionRow>
                    <iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSdu_AfJqaotG-qKSrcfLG8O1Y651xlNWSe88Rq_6TuutV2oZg/viewform?embedded=true"
                        width="100%" height="900" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
                    </iframe>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="light-dark"/>
            <Section shade="dark">
                <SectionRow>
                    <AppointmentDiv/>
                </SectionRow>
            </Section>
            {/*<SVGCurve direction="right-slope" color="text-ap-dark-bg" bgColor="bg-gray-200"/>*/}
        </LayoutGeneral>
    );
}


export default FeedbackPage;
